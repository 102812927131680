<template>
  <form class="flex flex-col items-center w-full"
        @submit="handleSubmit"
  >
    <div class="relative z-20 grid w-full mt-6 md:grid-cols-2 class gap-y-2 gap-x-4">
      <label class="text-sm md:order-1"> {{ jobFieldLabel }}</label>

      <Dropdown :items="jobFields"
                :value="refine.field"
                class="md:order-3"
                @change="(item:any) => setSelected(item, 'field')"
      />

      <label class="mt-2 text-sm lg:mt-0 md:order-2"> {{ locationLabel }}</label>

      <Dropdown :items="locations"
                :value="refine.location"
                class="md:order-4"
                @change="(item:any) => setSelected(item, 'location')"
      />
    </div>

    <label class="mt-4 mr-auto text-sm"> {{ inputLabel }}</label>
    <SectionNewsletterFormInput v-model="inputEmail"
                                :state="state"
                                :label="inputLabel"
                                :placeholder="inputPlaceholder"
                                class="w-full mt-2 md:w-full sm:w-full"
                                type="small"
    >
      <SectionNewsletterFormButton :state="state"
                                   :label="submitLabel"
                                   type="small"
      />
    </SectionNewsletterFormInput>

    <SectionNewsletterFormCheckbox :state="state"
                                   :copy="consentLabel"
                                   class="mt-6"
    />

    <SectionNewsletterFormToast :state="state"
                                :success-message="successMessage"
                                :error-message="errorMessage"
    />
  </form>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import useJobFilter from '../../../composables/job-filters'
import useNewsletter from '../../../composables/use-newsletter'

interface FormToastProps {
  jobFieldLabel: string
  locationLabel: string
  inputPlaceholder: string
  inputLabel: string
  submitLabel: string
  consentLabel: string
  successMessage: string
  errorMessage: string
}

defineProps<FormToastProps>()

const jobFields = useJobFilter('field')
const locations = useJobFilter('location')

const refine = ref({
  field: jobFields[0],
  location: locations[0]
})

const setSelected = (item: any, id: 'field' | 'location') => {
  refine.value[id] = item
}

const { subscribe, state } = useNewsletter()

const inputEmail = ref('')

function handleSubmit(e: Event) {
  e.preventDefault()

  subscribe(inputEmail.value, 164, refine.value.location.label, refine.value.field.label).then(() => {
    inputEmail.value = ''
  }).catch(() => {
    console.log('Subscribe failed')
  })
}
</script>
